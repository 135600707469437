import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components';
import './patient.css'
// import pic from '../../images/consultationjpeg2.jpg'
import pic from '../../images/illustrationbg.jpg'
import chatCons from '../../images/IMG-conschat.png'
import profile1 from '../../images/IMG_ph10.png'
import welcome from '../../images/IMG_ph12.png'
import doconphone from '../../images/doconphone.png'
import mobile from '../../images/docp.png'
import starFill from '../../images/star-filledd.png'
import starUnfill from '../../images/star-unfilled.png'
import mobile1 from '../../images/mobile.png'
import drJuwasan from '../../images/drjuwasan.jpeg'
import bashiru from '../../images/bashiru.jpeg'
import dummyPic from '../../images/mypic.jpg'
import appStore from '../../images/App Store.svg'
import playStore from '../../images/Play Store.svg'
import {BiCurrentLocation} from 'react-icons/bi'
import Faq from '../Faq/faq'

const Container = styled.div`
  ${tw`container pt-18 md:pt-36 mx-auto flex flex-wrap flex-col md:flex-row items-center`};
`

const StyledLink = styled(Link)`
  ${tw``};

`

const Patient = () => {

    const [doctors, setDocs] = React.useState([
        {img: drJuwasan, name: "Dr. Juwasan Odunayo",rating:"4.0", speciality: "General Physician",location:"Saudi Arabia", details: ""},
        {img: bashiru, name: "Mr. Bashiru Oyewole",rating:"5.0", speciality:"Mental Health Counsellor",location:"Nigeria", details: ""},
        {img: dummyPic, name: "Mrs. Aminat Abdul",rating: "5.0", speciality:"Life Coach",location:"Canada", details: ""},
    ])

    const starred = (
        <img class ="h-4 justify-self-center mb-4" src={starFill}></img>
    )

    const unstarred = (
         <img class="h-4 justify-self-center" src={starUnfill}></img>
    )

    function showRating(rating){

       for (let i = 0; i< 5; i++){
        //    return i < rating ? starred : unstarred
                return (<>
                <img class ="h-4 justify-self-center mb-4" src={starFill}></img> &nbsp;
                <span class="font-thin text-gray-700 "> {rating}</span>
                </>
                )
                // return <img class="h-4 justify-self-center" src={starUnfill}></img>
            
            
        }
    }

return ( <Container>
    
            <div class="flex flex-wrap w-full bg-white overflow-hidden patientmain">
                <div class="w-1/2 color  consultantimagemobile" >
                    <div id="" class="ml-auto">
                        {/* <div class="overlaay"> */}
                            <img src={doconphone} alt="" style={{height:365}} />
                            <div class="after">

                            </div>
                        {/* </div> */}

                    </div>
                </div>
                <div class="w-1/2 color px-3 self-center patientmain2">
                    <div>
                        {/* <div className="pt-16">
                            <StyledLink to="/">
                                <img src={hplogo} alt="healthpeer" className="cursor-pointer h-12 bounce-top-icons imagelogo pt-1" />
                            </StyledLink>
                        </div> */}
                        <div class="patient">
                            <h1 class="text-indigo-800">Get healthcare from home.</h1>
                            <p class="text-gray-500 tracking-wide">Realtime health consultations with verified professionals.</p>
                            {/* <p style={{ fontstyle: 'italic', fontSize: 14 }}>*Healthpeer lets you see a doctor* from your phone, where and when it’s convenient for you</p> */}
                        </div>
                    </div>
                </div>
                <div class="w-1/2 color patientimage" >
                    <div id="" class="ml-auto">
                        {/* <div class="overlaay"> */}
                            <img src={doconphone} class="patientimagemain" alt=""  />
                            <div class="after">

                            </div>
                        {/* </div> */}

                    </div>
                </div>
            </div>
            {/* doctors */}
        <div class="flex flex-wrap w-full bg-indigo-800 py-3 overflow-hidden ">
            <div class="w-full justify-content-center mb-5 element-animate">
                <div class="w-3/4 text-center mx-auto">
                    <h2 class="my-8 text-white font-medium text-3xl">OUR <strong class="font-bold">MEDICAL PROFESSIONALS</strong></h2>
                    <p class="mb-5 text-gray-100 lead">Meet some of our renowned specialist professionals from different medical cores 
                    to cater for your medical needs exclusively.</p>
                </div>
            </div>
            <div class="container flex justify-center patientdoctor">

            { doctors.map((doctor, i) =>  {
            return (
            <div key={i} class="w-1/4 mx-3 patientdoctorsingle">
                <div class="media d-block media-custom text-center">
                    <div class="flex items-center justify-center">
                    <img src={doctor.img} alt="Image Placeholder" class="img-fluid" />
                    </div>
                    <div class="flex-1 items-center justify-center pb-4">
                    <h3 class="my-0 text-black">{doctor.name}</h3>
                    <p class="text-gray-700 mb-0">{doctor.speciality}</p>
                    <div class="flex flex-row my-2 items-center justify-center">
                    <BiCurrentLocation class=" text-green-600" /> &nbsp;
                    <p class="text-gray-500 m-0">{doctor.location}</p>
                    </div>    
                        <span class="flex flex-row justify-center align-middle text-center">
                            {showRating(doctor.rating)}
                        </span>
                       
                    </div>
                </div>
                    </div> )
                    }) 
                    }

            </div>
        </div>
        <div class="flex flex-wrap w-full overflow-hidden">
            <div class="w-1/2 color px-3 patientphone">
                <div id="" class="mr-auto">
                    <div class="flex justify-center">
                        <img src={welcome} alt="" class="patientphone2 pt-5" />
                        <div class="">

                        </div>
                    </div>

                </div>
            </div>
            <div class="w-1/2 bg-indigo-100 patientfeatures" >
                <div class="my-48 patientfeaturesbox">
                    <div class=" patient1 my-20 mx-20 patientfeatures1">
                        <h4>Check your symptoms</h4>
                        <p class="mobile">Have peace of mind 24/7 with our chat-style Symptom Checker. Powered by Artificial Intelligence, it asks you questions about your symptoms to suggest possible courses of action.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex flex-wrap w-full overflow-hidden">
            <div class="w-1/2 px-3 bg-indigo-100 patientfeatures">
                <div class="my-48 patientfeaturesbox">
                    <div class="patient1 my-20 mx-20 patientfeatures1">
                        <h4>See a doctor</h4>
                        <p class="mobile">Have a text, audio or video consultations with any of our verified medical professionals, anytime anywhere.</p>
                    </div>
                </div>
            </div>
            <div class="w-1/2 color patientphone" >
                <div id="" class="ml-auto">
                    <div class="">
                        <img src={chatCons} class="patientphone2" alt="" />
                        <div class="">

                        </div>
                    </div>

                </div>

            </div>
        </div>
        <div class="flex flex-wrap w-full overflow-hidden">
            <div class="w-1/2 color px-3 patientphone">
                <div id="" class="ml-auto ">
                    <div class="">
                        <img src={profile1} class="patientphone2" alt="" />
                        <div class="">

                        </div>
                    </div>

                </div>
            </div>
            <div class="w-1/2 bg-indigo-100 patientfeatures" >
                <div class="my-48 patientfeaturesbox">
                    <div class="patient1 my-20 mx-20 patientfeatures1">
                        <h4>Get a prescription or referral</h4>
                        <p class="tracking-wide mobile">After your consultation, get prescriptions and referrals for diagnostic tests or specialists as needed, directly through the app.</p>
                    </div>
                </div>
            </div>
        </div>
        
        <div class ="flex-1 flex w-full justify-evenly items-center bg-indigo-800 h-64 upsh patientdownload">
                
                <div class="flex flex-col patientdownloadselect ">
                    <span  class="text-4xl font-thin text-white slide-in-bottom-h1"> Get started today</span>
                    <div class="flex flex-row text-center justify-center">
             
                        <a href="#">
                            <img src={appStore} class="h-8 mr-5 mb-0  hvr-push"/>
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=com.healthpeer.hpng">
                            <img src={playStore} class="h-8 mb-0  hvr-push" />
                        </a> 
                    </div>
                </div>
                <ol class="text-white text-xl mb-0 patientdowloadlist">
                   <li class="slide-in-bottom-subtitle">1. Download the app</li> 
                   <li class="slide-in-bottom-subtitle1">2. Register your account free</li> 
                   <li class="slide-in-bottom-subtitle2">3. Begin medical consultations</li> 
                </ol>
            </div>
        
        {/* <div class="flex-1 bg-gray-200">
        <h2 class="py-5 ml-8 pl-8 text-indigo-900 font-semibold text-4xl">Frequently Asked Questions...</h2>
       
        <Faq ></Faq>
        
        </div> */}
    </Container>
    )
    }

export default Patient
