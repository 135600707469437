import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import Patient from '../components/Patient/patient.js'

const SecondPage = () => (
    <Layout>
      <Patient />
    </Layout>
  )
  

export default SecondPage
