import React, { useState } from 'react';
import pic from '../../images/black-doctor.png';
import styled from 'styled-components';
import { Link } from 'gatsby'


const Button = styled.button`
  ${tw`bg-indigo-darker p-5 border-2 rounded text-sm text-white hover:bg-white hover:text-indigo-darker hover:border-indigo-darker`}
`

const Card = styled.div`
  ${tw`w-48 shadow-lg mx-6 p-2`}
`

const StyledLink = styled(Link)`
  ${tw`text-green-dark md:ml-2`};
`

const Container = styled.div`
  ${tw` flex-1 lg:mx-10`}
`

const MiniHeader = styled.p`
  ${tw`font-normal text-gray-dark px-8`}
`

const Drawer = styled.div`
    ${tw`p-3 cursor-pointer flex flex-row justify-between`}
`

const ALink = styled.p`
    ${tw`text-green-dark`}
`


const Faq = () => {
  const [opened, setOpen] = useState(false);
  
  const [faqs, setFaqs] = useState([
    {question:'What is Healthpeer ?', opened:false,
    answer: "Healthpeer is a mobile medical application that gives you direct access to reliable consultants to solve your medical related challenges at your own time right on your mobile phone. Get closer to your doctor without any hassle."},
    {question:'How does the symptom checker work ?',opened:false, 
    answer: "Healthpeer symptom checker is an AI powered bot that enagages you in a chat-like manner to confirm your symptoms whilst also advising you on your diets & nutritions."},
    {question:'When do I need to use the healthpeer app ?',opened:false, 
    answer: "You can use healthpeer at your convinience, when you feel any discomfort or symptoms. Quickly pickup your phone and speak to a consultant at will. Early Diagnosis is a life-saver."},
    {question:'What services does healthpeer offer ?',opened:false, 
    answer: "Healthpeer offers a range of services to patients. These include; Doctor Consultancy through text/voice chat. Medication/Prescription purchase and delivery. History of Diagnosis and prescriptions. Therapies (Depression/Anger Management).Threads and Sensitization of patients by doctor (in-app)."},
    {question:'How do I pay for the service ?',opened:false, 
    answer: "There is a simple and flexible in-app payment method via debit/credit card.You buy a session(s) to get access to a consultant."},
    {question:'What are the specialities of healthpeer consultants ?',opened:false, 
    answer: "HealthPeer allows consultancy in different medical fields such as: General Consultancy. Family Planning. Dermatology. Pediatrics. Gynaecology. Obstetrics - Pre-Natal consultations for only pregnancies between 20 weeks. Psychology/Therapies. Dental/Optical health. Dietary/Fitness. Mental Health."},
    {question:'Does the service cover medications and lab test ?',opened:false, 
    answer: "Yes, Patients are reffered to designated pharmacy/labs for tests at a discounted cost. Moreover,You could also order for test kis and take your tests yourself or get help from our dispatch nurses for home seviced tests. This comes with additional costs."},    
     {question:'Can I get consultation without physical contact ?',opened:false, 
    answer: "Yes, the motive of the application is to get medical advices & consultation at your own comfort with minimal physical contact. However, we do not cover emergencies."},    
    {question:'How secure is my personal information ?',opened:false, 
    answer: " Healthpeer treats all patient's information with utmost confidentiality and ensures your medical information are only visible to you. Check our privacy policy for additional information."},
    {question:"Do consultants have access to patient's medical Information ?",opened:false, 
    answer: "Consultants only have access to your informaion based on your profile or directly requesting from patient during their private session(s). Such information is used only for diagnosis or to aid consultation."},
    {question:'Does the app services extend to emergencies ?',opened:false, 
    answer: "No. It does not, Please contact the nearest emergency service near you."},
    {question:'What kind of medical services can I get ?',opened:false, 
    answer: "Our major service is consultancy with specialists in most medical fields. Meanwhile you can also order medications as well as get referrals to labs for physical tests."},
    {question:'Can I order medications ?',opened:false, 
    answer: "Yes ! You can order medications at discounted prices directly from the app. However, Healthpeer does not directly interfere with drug delivery as orders can be made from your pharmacy of choice."},
    {question:' What do I do when no medical professional is online ?',opened:false, 
    answer: "On the Healthpeer app, consultants are always online to help you. However, you can initiate a special appointment in a particular Doctor's absense The Doctor will get back to you as soon as possible."},
    {question:'What is a special appointment ?',opened:false, 
    answer: "Special appointments are appointments booked by patients to speak to a particular Consultant every other time. This will incurr additional costs."},
    {question:'When does my session start ?',opened:false, 
    answer: "Your session begins immediately you make payment and join the consultation room."},
    {question:'How long is a session ?',opened:false, 
    answer: "A session is determined by a medical professional which inflences the amount charged per session."},    
    {question:'What happens if my session ends during consultaion ?',opened:false, 
    answer: "Sessions are timed and as such, you will have a countdown in the consultation room to be aware of the time. Meanwhile, You have to initiate another session."},    
    {question:'Can I cancel a session ?',opened:false, 
    answer: "Any session can be ended by the patient, Although sessions can be left to lapse within the allocated timeline."},
    {question:' Does the same consultant attend to me all the time ?',opened:false, 
    answer: "No ! You select any consultant of your choice per session."}   
  ])

  const [ currPage, setCurrPage] = React.useState('')

 
  React.useEffect(() => {
    setCurrPage(window.location.pathname)
  },[])
  // const handleOpen = () => setOpen(!opened)
  const updateFieldChanged = (opnd, index) => (event) => {
    let newArr = faqs.map((item, i) => {
      if (index == i) {
        return { ...item, opened: !opnd };
      } else {
        return item;
      }
    });
    setFaqs(newArr);
  };


  const up = <span class="font-bold text-lg" style={{color: "green"}}> 
  {/* &or;{"  "}  */}
  +{" "}
  </span>
  const down = (
    <span class="font-bold text-lg" style={{ color: 'green' }}>
      {/* &and;{"  "} */}
      - {" "}
    </span>
  )

  function dispFaq(){
    return faqs.map((faq, i) => { 
      if (i < 5){
      return (<div key={i}>
      <Drawer  onClick={updateFieldChanged(faq.opened, i)}>
        <h3 class="m-0 font-thin text-gray-700">
          {' '}
          {faq.opened ? down : up}{faq.question}
        </h3>
        
      </Drawer>
      {faq.opened && <MiniHeader>{faq.answer}</MiniHeader> }
      <hr />
      </div>
      )
      }
      })
    
  }

  function showFaqs (){
   return faqs.map((faq, i) => { 
      return (<div key={i}>
      <Drawer  onClick={updateFieldChanged(faq.opened, i)}>
        <h3 class="m-0 font-thin text-gray-700">
          {' '}
          {faq.opened ? down : up}{faq.question}
        </h3>
        
      </Drawer>
      {faq.opened && <MiniHeader>{faq.answer}</MiniHeader> }
      <hr />
      </div>
      )
      })
  }

  return (
    <>
      <div>
       
        <Container style={{paddingTop: 80 }} class="faqlist">
        {/* <h2 class="py-5  pl-2 text-indigo-900 font-semibold text-4xl">Frequently Asked Questions...</h2> */}

          <div style={{ transition: '1s' }}>

            {currPage == '/faqs' ? 
            <div> 
              <h2 class="py-3 pl-8 text-indigo-900 font-semibold text-4xl">Frequently Asked Questions...</h2>
              {showFaqs()}
            </div> 
            : <div class="mb-3 allfaq" > 
                <h2 class="pl-2 text-indigo-900 font-semibold text-4xl">Some of your questions answered...</h2>
              {dispFaq()} 
              <StyledLink  to="/faqs" >See all FAQs</StyledLink>
              </div>
            }
          </div>

        </Container>
      </div>
    </>
  )
}

export default Faq
